<template>
  <!-- TODO: Add header. -->
  <div class="main">
    <slot />
  </div>
  <!-- TODO: Add footer. -->
</template>

<script>
  import { computed, ref } from 'vue'
  import { useRoute } from 'vue-router'

  export default {
    name: 'MainLayout',
    setup() {
      const route = useRoute()
      const displayDrawer = ref(false)
      const hasFixedMenu = ['/', '/home']
      const fixedClass = computed(() => hasFixedMenu.includes(route.path))

      return { displayDrawer, fixedClass, route }
    },
  }
</script>

<style lang="scss" scoped>
  .main {
    width: 100%;
    height: 100%;
    background: #ffffff;
    &__menu {
      position: relative;
      top: 0;
      width: 100%;
      z-index: 100;
      // background: rgba(0,0,0,0.3);
      &.fixed {
        position: fixed;
      }
    }
  }
</style>
