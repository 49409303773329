import { createRouter, createWebHistory } from 'vue-router'

import {
  CASE_INFORMATION_ROUTE,
  INDEX_ROUTE,
  LOG_IN_ROUTE,
  ADMIN_VIEW_ROUTE,
  CASE_SUMMARY_ROUTE,
  THANK_YOU,
} from '../constants/routes'

const routes = [
  {
    path: INDEX_ROUTE,
    name: 'Home',
    children: [{ path: '/:id' }],
    component: () => import('@/views/Index'),
  },
  {
    path: CASE_INFORMATION_ROUTE,
    name: 'Case Information',
    component: () => import('@/views/CaseInformation'),
  },
  {
    path: LOG_IN_ROUTE,
    name: 'Log in',
    component: () => import('@/views/LogIn'),
  },
  {
    path: ADMIN_VIEW_ROUTE,
    name: 'Admin View',
    component: () => import('@/views/AdminView'),
  },
  {
    path: CASE_SUMMARY_ROUTE,
    name: 'Case summary',
    component: () => import('@/views/CaseSummary'),
  },
  {
    path: THANK_YOU,
    name: 'Thank you',
    component: () => import('@/views/ThankYou'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
