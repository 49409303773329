<template>
  <el-menu
    :default-active="activeIndex"
    class="el-menu-demo"
    mode="horizontal"
    :ellipsis="false"
    @select="handleSelect"
  >
    <el-menu-item index="0"
      ><a :href="`/${this.customer}`">Halsted Law Group</a></el-menu-item
    >
    <div class="flex-grow" />
    <el-menu-item index="1"><a href="/login">LogIn</a></el-menu-item>
  </el-menu>
</template>

<script>
  export default {
    name: 'Header',
    props: {
      customer: String,
    },
  }
</script>

<style scoped>
  header {
    height: 90px;
  }

  a {
    text-decoration: none;
  }

  .flex-grow {
    flex-grow: 1;
  }
  div.center {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
  }

  img {
    width: 60px;
    height: 60px;
  }
</style>
