<template>
  <div v-if="!loading">
    <el-container>
      <el-header>
        <Header :customer="this.customer" />
      </el-header>
      <el-main>
        <Layout>
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </Layout>
      </el-main>
    </el-container>
  </div>
</template>

<script>
  import { ref } from 'vue'
  import Header from '@/components/molecules/Header'
  import Layout from '@/layouts/Layout'

  export default {
    name: 'App',

    components: {
      Layout,
      Header,
    },
    data() {
      return {
        customer: location.pathname.split('/')[1],
      }
    },
    setup() {
      const loading = ref(false)

      return {
        loading,
      }
    },
  }
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
</style>
